import React from "react"
import Title from "./globals/Title"
const Contact = ({ title, children }) => (
  <section className="contact py-5" id="contact">
    <div className="container">
      <Title title="Contact Me" />
      <div className="row">
        <div className="col-10 col-sm-8 col-md-6">
          <form method="POST" action="https://formspree.io/tjyobazee@yahoo.com">
            <input
              type="hidden"
              name="_next"
              value="https://gistpages.com/thanks"
            />
            <input
              type="hidden"
              name="_subject"
              value="From personal website"
            />
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                id="name"
                placeholder="John Smith"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="john.smith@email.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description" className="sr-only">
                Description
              </label>
              <textarea
                name="description"
                id="description"
                className="form-control"
                rows="5"
                placeholder="I'd like to hire you!"
              ></textarea>
            </div>
            <button className="btn btn-black text-capitalize">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </section>
)

export default Contact
