import React, { useState } from "react"
import Title from "./globals/Title"
import Img from "gatsby-image"

const Menu = props => {
  const [items, setItems] = useState(props.items.edges)
  const [originalItems] = useState(props.items.edges)
  const [categories] = useState([
    "all",
    ...new Set(items.map(item => item.node.category)),
  ])

  const handleCategorization = category => {
    if (category === "all") {
      setItems(originalItems)
    } else {
      setItems(originalItems.filter(item => item.node.category === category))
    }
  }

  return items.length > 0 ? (
    <section className="menu py-5 bg-light" id="skills">
      <div className="container">
        <Title title="Skills" />
        <div className="row">
          <div className="col-10 mx-auto text-center">
            {categories.map((category, index) => (
              <button
                key={index}
                className="btn btn-black text-capitalize m-3"
                onClick={() => handleCategorization(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
        <div className="row mb-5">
          {items.map(({ node }) => {
            return (
              <div
                key={node.id}
                className="col-11 col-md-6 my-3 d-flex mx-auto"
              >
                <div>
                  <Img fixed={node.image.fixed} />
                </div>
                <div className="flex-grow-1 px-3 d-flex flex-column justify-content-center">
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0">{node.title}</h5>
                  </div>
                  <div className="text-muted">
                    {node.description.description}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  ) : (
    <section className="menu py-5">
      <div className="container">
        <Title title="Skills" />
        <div className="row">
          <div className="col-10 col-sm-6 mx-auto text-center">
            <h1>There is nothing here to display</h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Menu
