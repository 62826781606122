import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../components/globals/Background"
import Info from "../components/Info"
import Menu from "../components/Menu"
import Contact from "../components/Contact"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={["rails engineer", "web developer", "project management"]}
    />
    <Background
      img={data.img.childImageSharp.fluid}
      styleClass="about-background"
    ></Background>
    <Info title="About">
      <div className="row">
        <div className="col-md-8 col-xs-12">
          <p className="mb-5">
            <span className="bg-dark text-white py-1 px-2">
              Backend Development
            </span>{" "}
            &mdash; I have been developing web applications since 2003. I
            started as a <span className="font-italic">PHP developer</span>{" "}
            working with <span className="font-italic">CodeIgniter</span> and{" "}
            <span className="font-italic">CakePHP</span>. I have been using Ruby
            and along with the{" "}
            <span className="font-weight-bold">
              <a
                href="https://rubyonrails.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ruby on Rails
              </a>
            </span>{" "}
            framework for the past 9 years. I have used it for all sorts of
            projects; full CMS websites, database-driven web applications and
            REST-driven frontend applications.
          </p>
          <p className="mb-5">
            <span className="bg-dark text-white py-1 px-2">
              Frontend Development
            </span>{" "}
            &mdash; Developing with Javascript has been been very exciting in
            the past 5 years.{" "}
            <span className="font-weight-bold">
              <a
                href="https://reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ReactJS
              </a>
            </span>{" "}
            has been my tool of choice when it comes to frontend development.
          </p>
        </div>
        <div className="col-md-4 col-xs-12 bg-light py-2">
          <p className="lead text-muted mb-5">
            You can see some of the things I've worked on over on my{" "}
            <a
              href="https://github.com/pinoytech"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>{" "}
            page.
          </p>
          <p className="lead text-muted mb-5">
            Alternatively, you can find me on{" "}
            <a
              href="https://stackoverflow.com/users/37532/teej"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stackoverflow
            </a>
            . If you are interested in working together, don't hesitate to{" "}
            <Link to="/about#contact">get in touch</Link>.
          </p>
        </div>
      </div>
    </Info>
    <Menu items={data.menu} />
    <Contact />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "about-background.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    menu: allContentfulSkills {
      edges {
        node {
          id
          title
          description {
            description
          }
          category
          image {
            fixed(width: 100, height: 100) {
              ...GatsbyContentfulFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default IndexPage
